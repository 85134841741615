.page-footer__nav {
    position: relative;
    padding: 0 3rem 0 7rem;
    box-sizing: border-box;
}

.page-footer__nav__title {
    color: var(--color-purple-lighter);
    font-family: var(--font-sofia);
    font-size: 2.7rem;
    letter-spacing: .1rem;
    font-weight: var(--font-weight-light);
    line-height: 1.5;
    transform: rotate(270deg) translateX(-100%);
    display: block;
    transform-origin: 0 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    text-align: right;
    padding-right: .5rem;
    opacity: .5;
}

.page-footer__nav__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.page-footer__nav__list__item {
    display: block;
    line-height: 2;

    a {
        text-decoration: none;
        font-weight: var(--font-weight-light);
        font-size: 1.6rem;
        transition: all .2s ease;

        @media (--screen-medium-large) {
            font-size: 1.8rem;
        }

        &:hover,
        &:focus {
            color: var(--color-purple-lighter);
        }
    }
}
