.rooms-filter {
    background: var(--color-beige);
    margin: 0 -2rem 0;
    padding: 2rem;
    border-top: .1rem solid var(--color-gray-lighter);
    border-bottom: .1rem solid var(--color-gray-lighter);
    min-height: 6rem;

    @media (--screen-large) {
        margin: 0 -7rem 0;
        padding: 0 7rem;
    }
}

.rooms-filter__list {
    margin: 0;
    padding: 0;
    list-style: none;

    @media (--screen-mini-medium) {
        position: relative;
        z-index: 3;
        display: flex;
        flex-direction: column;

        &.-active {
            position: absolute;
            width: calc(100% - 4rem);
            li {
                display: block;
            }
        }

        &::after {
            content: '';
            display: block;
            width: 1rem;
            height: 1rem;
            background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2IiB3aWR0aD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTE1ODAuMjE5NjcgMzY1Ny43ODA2NGMtLjI5Mjg5LjI3ODk0LS4yOTI4OS43MzEyIDAgMS4wMTAxNXMuNzY3NzcuMjc4OTUgMS4wNjA2NiAwbDQuNS00LjI4NTcxYy4yOTI4OS0uMjc4OTUuMjkyODktLjczMTIxIDAtMS4wMTAxNmwtNC41LTQuMjg1NzFjLS4yOTI4OS0uMjc4OTUtLjc2Nzc3LS4yNzg5NS0xLjA2MDY2IDBzLS4yOTI4OS43MzEyMSAwIDEuMDEwMTVsMy45Njk2NyAzLjc4MDY0eiIgZmlsbD0iI2FhYTM5NSIgdHJhbnNmb3JtPSJtYXRyaXgoMCAxIC0xIDAgMzY1OSAtMTU4MCkiLz48L3N2Zz4=') no-repeat center center;
            position: absolute;
            top: 2.5rem;
            right: 1.5rem;
        }
    }

    li {
        display: block;

        @media (--screen-mini-medium) {
            display: none;
            width: 100%;
            background: var(--color-white);
            border: .1rem solid var(--color-gray-lighter);

            &.-active {
                display: block;
                order: -1;
            }
        }

        @media (--screen-large) {
            display: inline-block;
            padding-right: 4rem;
        }
    }
}

.rooms-filter__item {
    display: block;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 2rem;
    color: var(--color-gray-light);
    padding: 1.5rem;
    text-decoration: none;
    position: relative;

    @media (--screen-large) {
        padding: 2.7rem 0;
    }

    small {
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-normal);
        font-size: 1.3rem;
        color: var(--color-gray-light);
        padding-left: .7rem;
        display: inline-block;
    }

    &:hover,
    &:focus {
        color: var(--color-purple-light);
    }

    &::after {
        display: none;
        content: '';
        width: 100%;
        height: .4rem;
        position: absolute;
        background: var(--color-purple-light);
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.-active {
        color: var(--color-black);
        &::after {
            @media (--screen-large) {
                display: block;
            }
        }
    }
}