.page-footer__siblings {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;

    a {
        display: inline-block;
        margin: 0 4rem 4rem 0;

        &:last-child {
            margin-right: 0;
        }
    }

    svg {
        fill: red;
        height: 3.8rem;
        width: 100%;
    }

    @media (--screen-large) {
        margin-bottom: -4rem;
        justify-content: flex-start;
    }

    @media (--screen-xlarge) {
        margin-top: 22rem;
    }
}
