.event-cases {
    padding: 0 2rem;

    > p {
        color: var(--color-gray-light);
        font-size: 1.3rem;
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-normal);
        text-transform: uppercase;
        letter-spacing: .1rem;
        margin: 0 auto;
    }

    @media (--screen-large) {
        padding: 0 7rem;
    }

    &.-home {
        padding: 6rem 2rem;
        background: var(--color-beige);
        text-align: center;

        @media (--screen-large) {
            padding: 7rem;
        }
    }
}

.event-cases__title {
    display: block;
    text-align: center;
    margin: 0 0 1.5rem;
    color: var(--color-blue-dark);
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    font-size: 3rem;

    @media (--screen-medium) {
        font-size: 3.7rem;
    }

    @media (--screen-large) {
        font-size: 4.6rem;
    }
}

.event-cases__list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    margin: 3rem -1rem;

    @media (--screen-large) {
        margin: 3rem -1.5rem;
    }
}

.event-cases__item {
    display: block;
    position: relative;

    margin: 1rem;
    text-decoration: none;

    &:hover,
    &:focus {
        .event-cases__item__image::before {
            opacity: 1;
        }
    }

    @media (--screen-medium) {
        flex-basis: calc(50% - 2rem);
        width: calc(50% - 2rem);
        margin: 1rem;
    }

    @media (--screen-large) {
        flex-basis: calc(33% - 3rem);
        width: calc(33% - 3rem);
        margin: 1.5rem;
    }
}

.event-cases__item__image {
    position: relative;

    img {
        width: 100%;
    }

    &::after, &::before {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: linear-gradient(180deg, rgba(0, 0, 0, 0) 33%, rgba(0, 0, 0, .76) 100%);
    }

    &::before {
        background: rgba(0, 0, 0, .2);
        opacity: 0;
        transition: all .2s ease;
    }
}

.event-cases__item__title {
    display: block;
    color: var(--color-white);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-normal);
    font-size: 2rem;
    margin-bottom: 1rem;
}

.event-cases__item__content {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 1;
    padding: 2rem;
    color: var(--color-white);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    text-align: left;

    > p {
        margin: 0;
        opacity: .8;
    }
}
