.newsletter {
    background: url('newsletter.jpg') no-repeat 0 0;
    background-size: cover;
    min-height: 30rem;
    box-sizing: border-box;
    padding: 4rem 2rem;

    @media (--screen-large) {
        padding: 8rem;
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
    }

    .form__label {
        margin-bottom: 3rem;
    }

    .form__field {
        background: var(--color-white);
        padding-right: 6rem;
    }

    .form__message {
        position: absolute;
    }
}

.newsletter__column {
    margin: 0 auto;
    max-width: 50rem;

    @media (--screen-large) {
        flex-basis: 50%;
        margin: 0;
    }
}

.newsletter__button {
    border: 0;
    background: none;
    outline: none;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    padding: 1.5rem;
    font-size: 0;
    line-height: 2.6rem;
    cursor: pointer;

    &::before {
        display: block;
        content: '';
        width: 3rem;
        height: 1.2rem;
        background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMzcgMTIiIHdpZHRoPSIzNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3MjU4MyIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yMykiPjxwYXRoIGQ9Im0zMyAyOWgtMzIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJtMzAgMjQgNSA1LTUgNSIvPjwvZz48L3N2Zz4=') no-repeat 100% 50%;
        margin-right: 2rem;
        transition: all .2s ease;
        position: absolute;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
    }

    &:hover, &:focus {
        color: var(--color-purple);

        &::before {
            width: 4rem;
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMzcgMTIiIHdpZHRoPSIzNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2QzYzdiMSIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yMykiPjxwYXRoIGQ9Im0zMyAyOWgtMzIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJtMzAgMjQgNSA1LTUgNSIvPjwvZz48L3N2Zz4=');
            transform: translate(1rem, -50%);
        }
    }
}

.newsletter__field {
    position: relative;
}

.newsletter__title {
    font-size: 3rem;
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    display: block;
    line-height: 1.2;
    margin: 0 auto;

    @media (--screen-medium) {
        font-size: 3.7rem;
    }

    @media (--screen-large) {
        max-width: 33rem;
        font-size: 4.6rem;
    }
}

.newsletter__message {
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 0 0 .5rem;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }
}
