form,
.form {
    width: 100%;
}

.form__title {
    line-height: 1.2;
    font-size: 3rem;
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    margin: 0 0 3rem;

    @media (--screen-medium-large) {
        font-size: 3.7rem;
    }
}

.form__info {
    display: block;
    line-height: 1.6;
    font-size: 1.6rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    margin-bottom: 3rem;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }
}

.form__img {
    text-align: center;
    margin-bottom: 3rem;

    img {
        max-width: 100%;
    }

    @media (--screen-large) {
        text-align: left;
        margin-bottom: 0;
        padding-right: 7rem;
    }
}

@media (--screen-medium-large) {
    form, .form {
        max-width: 62rem;
        margin: 0 auto;
    }
}
