.navigation {
    display: none;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .-menu-active & {
        @media (--screen-mini-large) {
            display: block;
        }
    }

    @media (--screen-xlarge) {
        display: block;
        margin-top: 8rem;
    }
}

.navigation__item {
    display: block;

    a {
        display: inline-block;
        transition: all .2s ease;
        font-size: 2.4rem;
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-medium);
        line-height: 2.2;
        text-decoration: none;
        color: var(--color-purple-light);

        &:hover,
        &:focus {
            color: var(--color-purple);
            transform: translateX(3rem);

            @media (--screen-xlarge) {
                transform: none;
            }
        }

        .-menu-active & {
            font-family: var(--font-kepler);
        }

        @media (--screen-medium) {
            .-menu-active & {
                font-size: 3rem;
            }
        }

        @media (--screen-large-xlarge) {
            font-size: 1.7rem;

            .-menu-active & {
                font-size: 3.7rem;
            }
        }

        @media (--screen-xlarge) {
            font-size: 1.7rem;
            position: relative;

            .-menu-active & {
                font-family: var(--font-sofia);
            }

            &::after {
                transition: all .2s ease;
                display: block;
                content: '';
                width: 30rem;
                height: .2rem;
                background: var(--color-gold);
                position: absolute;
                top: 1.8rem;
                right: 0;
                transform: translateX(calc(100% + 30rem));

                @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                    top: 2rem;
                    transform: translateX(200%);
                }
            }

            &:hover,
            &:focus {
                &::after {
                    transform: translateX(calc(100% + 2rem));

                    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                        transform: translateX(105%);
                    }
                }
            }
        }
    }
}
