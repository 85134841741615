.form__field__hidden {
    display: none;
}

%form__field-reset {
    transition: all .2s ease;
    box-sizing: border-box;
    border-radius: 0;

    -webkit-appearance: none !important; // HARD reset to remove shadow in input for IOS

    display: inline-block;
    width: 100%;
    margin: 0;
    padding: 1.5rem;
    vertical-align: baseline;

    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 2.6rem;

    color: var(--color-black);
    background: none;
    border: .1rem solid var(--color-gray-lighter);

    // Firefox outline style
    outline: none;
}

// FF cursor pointer bug fix
input {
    cursor: pointer;
}

[type='date'] {
    &::-webkit-datetime-edit {
        transition: all .3s ease;
    }

    &.-has-value,
    &:focus {
        &::-webkit-datetime-edit {
            opacity: 1;
        }
    }
}

.form__field {
    @extend %form__field-reset;

    &.-textarea {
        resize: vertical;
    }

    &:focus {
        outline: none;
        border-color: var(--color-purple-light);
    }

    &::placeholder {
        color: var(--color-gray-light);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 1.6rem;
    }

    &[disabled] {
        background: var(--color-gray-lighter);
        opacity: .5;
    }

    .form__entry.-invalid & {
        border-color: var(--color-red);

        // Firefox outline style & shadow
        outline: none;
        box-shadow: none;
    }

    .form__entry.-password-strength & {
        border-radius: 1rem 1rem 0 0;
    }

    &.form__field--radio {
        border: none;
        padding: 1rem 0;
    }

    &[type='checkbox'], &[type='radio'] {
        border: 0;
        display: none;

        + .form__label {
            position: relative;
            top: auto;
            transform: none !important;
            display: block;
            padding: 0 0 0 3.2rem;
            color: var(--color-black);
            font-family: var(--font-sofia);
            font-weight: var(--font-weight-light);
            font-size: 1.6rem;
            line-height: 1.5;

            &::before,
            &::after {
                content: '';
                display: block;
                width: 1.6rem;
                height: 1.6rem;
                position: absolute;
                top: .2rem;
                left: 0;
            }

            &::before {
                box-shadow: inset 0 0 0 .1rem var(--color-gray-lighter);
            }

            &::after {
                z-index: 1;
                background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjkiIHZpZXdCb3g9IjAgMCAxMSA5IiB3aWR0aD0iMTEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTQgNi42ODUyMzI3MSAzLjQ5NzU4MDk5IDMuMzE0NzY3MjkgNS41MDI0MTkwMS02IiBmaWxsPSJub25lIiBzdHJva2U9IiNmZmYiIHN0cm9rZS13aWR0aD0iMiIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTMgLTMpIi8+PC9zdmc+');
                background-repeat: no-repeat;
                background-position: center center;
                top: .2rem;
                left: 0;
                opacity: 0;
                transition: all .2s ease;
                transform: scale(0);
            }
        }

        &:checked + .form__label::before {
            box-shadow: inset 0 0 0 .1rem var(--color-purple-light);
            background-color: var(--color-purple-light);
        }

        &:checked + .form__label::after {
            opacity: 1;
            transform: scale(1);
        }
    }

    &[type='radio'] + .form__label::before {
        border-radius: 50%;
    }

    &[type='radio'] + .form__label::after {
        background: var(--color-white);
        width: .6rem;
        height: .6rem;
        border-radius: 50%;
        top: .7rem;
        left: .5rem;
    }
}

.form__select {
    border: .1rem solid var(--color-gray-lighter);
    padding-right: 3.6rem;
    cursor: pointer;
    color: var(--color-black);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;

    &:invalid {
        color: var(--color-gray-light);
    }
}

.form__select__container {
    position: relative;

    &::after {
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjYiIHZpZXdCb3g9IjAgMCAxMCA2IiB3aWR0aD0iMTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTE1ODAuMjE5NjcgMzY1Ny43ODA2NGMtLjI5Mjg5LjI3ODk0LS4yOTI4OS43MzEyIDAgMS4wMTAxNXMuNzY3NzcuMjc4OTUgMS4wNjA2NiAwbDQuNS00LjI4NTcxYy4yOTI4OS0uMjc4OTUuMjkyODktLjczMTIxIDAtMS4wMTAxNmwtNC41LTQuMjg1NzFjLS4yOTI4OS0uMjc4OTUtLjc2Nzc3LS4yNzg5NS0xLjA2MDY2IDBzLS4yOTI4OS43MzEyMSAwIDEuMDEwMTVsMy45Njk2NyAzLjc4MDY0eiIgZmlsbD0iI2FhYTM5NSIgdHJhbnNmb3JtPSJtYXRyaXgoMCAxIC0xIDAgMzY1OSAtMTU4MCkiLz48L3N2Zz4=') no-repeat center center;
        position: absolute;
        top: 50%;
        right: 1.5rem;
        transform: translateY(-50%);
    }
}

.form__option {
    position: relative;
    display: block;
    margin: 1rem 0;
}
