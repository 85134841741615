.page-footer__social {
    position: relative;
    z-index: 1;
    min-width: 12.4rem;
    text-align: center;
}

.page-footer__social__item {
    display: inline-block;
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
    border: .1rem solid color-mod(var(--color-purple-lighter) a(30%));
    text-align: center;
    line-height: 4.8rem;
    margin: 2rem 2rem 0 0;
    transition: all .2s ease;

    img {
        vertical-align: middle;
    }

    &:last-child {
        margin-right: 0;
    }

    &:hover,
    &:focus {
        background: color-mod(var(--color-purple-lighter) a(30%));
    }

    @media (--screen-large) {
        float: left;
    }

    @media (--screen-xlarge) {
        &:nth-child(2n) {
            margin-right: 0;
        }

        &:nth-child(2n + 1) {
            clear: left;
        }
    }
}
