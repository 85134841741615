/* page-header */
.page-header {
    display: block;
    background: var(--color-purple-light);
    margin-bottom: 14rem;
    position: relative;
    line-height: 0;
    font-size: 0;

    .-hide-sidebar-and-footer & {
        margin-bottom: 8rem;
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: url('default.jpg') no-repeat 0 0;
        background-size: cover;

        @media (--screen-large) {
            background-size: contain;
        }
    }

    &.-bg-butterfly {
        &::after {
            background-image: url('butterfly.jpg');
        }
    }

    &.-bg-flamingo {
        &::after {
            background-image: url('flamingo.jpg');
        }
    }

    &.-bg-gorilla {
        &::after {
            background-image: url('gorilla.jpg');
        }
    }

    &.-bg-meerkats {
        &::after {
            background-image: url('meerkats.jpg');
        }
    }

    &.-bg-okapi {
        &::after {
            background-image: url('okapi.jpg');
        }
    }

    &.-bg-peacock {
        &::after {
            background-image: url('peacock.jpg');
        }
    }

    &.-bg-pelican {
        &::after {
            background-image: url('pelican.jpg');
        }
    }

    &.-bg-sea-horse {
        &::after {
            background-image: url('sea_horse.jpg');
        }
    }

    @media (--screen-large) {
        margin-bottom: 15rem;
    }

    &.-room {
        margin-bottom: 7rem;
        &.-no-media {
            margin-bottom: 15rem;
        }
        @media (--screen-xlarge) {
            margin-bottom: 15rem;
            &.-no-media {
                margin-bottom: 23rem;
            }
        }
    }

    &.-error {
        min-height: 30rem;
        margin-bottom: 7rem;

        .page-header__image {
            display: none;
        }
    }

    &.-rooms {
        margin-bottom: 9rem;

        @media (--screen-large) {
            margin-bottom: 12rem;
        }
    }
}

.page-header__content {
    display: block;
    box-sizing: border-box;
    padding: 7rem 2rem 3rem;
    position: relative;
    z-index: 2;

    @media (--screen-large) {
        position: absolute;
        bottom: 0;
        left: 0;
        padding: 7rem;
        max-width: 80rem;

        .page-header.-no-media:not(.-home):not(.-room) &,
        .page-header.-eventcase &,
        .page-header.-news & {
            min-height: 31rem;
            position: relative;
            display: flex;
            flex-direction: column;
            flex-wrap: wrap;
            justify-content: flex-end;
        }

        .page-header.-eventcase & {
            min-height: 45rem;
        }
    }

    h1, h2 {
        color: var(--color-white);
        margin: 0;
        padding: 0;
        line-height: 1.05;
    }

    h1 {
        font-family: var(--font-kepler);
        font-weight: var(--font-weight-medium);
        font-size: 4rem;

        @media (--screen-large) {
            font-size: 6.6rem;
        }
    }

    h2 {
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 2rem;
        margin: 0 0 4rem;
        line-height: 1.2;

        @media (--screen-large) {
            font-size: 3rem;
        }
    }

    .page-header.-home & {
        top: 0;
        right: 0;
        padding: 7rem 2rem;

        h1 {
            font-size: 4rem;
            margin: 0 0 2rem;

            @media (--screen-large) {
                font-size: 7.6rem;
                margin: 0 0 4rem;
            }
        }

        @media (--screen-large) {
            padding: 7rem;
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    .page-header.-eventcase & {
        @media (--screen-large) {
            padding: 7rem 7rem 4rem;
        }
    }

    .page-header.-error & {
        max-width: 100%;
        padding: 7rem 2rem;

        @media (--screen-large) {
            padding: 7rem;
        }
    }
}

.page-header__image {
    width: calc(100% - 2rem);
    float: right;
    margin-bottom: -7rem;
    position: relative;
    z-index: 1;

    .page-header.-home & {
        width: calc(100% - 2rem);

        @media (--screen-large) {
            width: 100%;
        }

        @media (--screen-xlarge) {
            min-width: 108rem;
            width: calc(100% - 44rem);
        }
    }

    .page-header.-room & {
        width: calc(100% - 2rem);

        @media (--screen-large) {
            width: calc(100% - 44rem);

            img {
                min-height: 48rem;
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                position: relative;
                min-height: 48rem;
            }

        }

        @media (--screen-xlarge) {
            margin-bottom: -15rem;
        }
    }

    .page-header.-no-media:not(.-home):not(.-room) &,
    .page-header.-eventcase &,
    .page-header.-news & {
        @media (--screen-large) {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
        }
    }

    img {
        width: 100%;
        min-height: 16rem;
        max-height: 66rem;
        object-fit: cover;

        @media (--screen-large) {
            min-height: 38rem;
            height: 100%;

            .page-header.-eventcase & {
                min-height: 52rem;
            }

            @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                .page-header.-room & {
                    position: absolute;
                }
            }
        }
    }

    &::after {
        display: block;
        content: '';
        background-image: linear-gradient(245deg, rgba(0, 0, 0, 0) 32%, rgba(0, 0, 0, .72) 100%);
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 1;
    }

    @media (--screen-large) {
        max-height: 66rem;
        min-height: 38rem;
        width: calc(100% - 44rem);
    }

    &.-full {
        width: 100%;
        max-height: 100rem;
    }
}

.page-header__image__button {
    display: none;
    width: 5rem;
    height: 5rem;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 2;
    background: var(--color-beige);
    transition: all .2s ease;

    &::after {
        display: block;
        content: '';
        background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjgiIHZpZXdCb3g9IjAgMCAxNCA4IiB3aWR0aD0iMTQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTM0IDMwIDYgNi02IDYiIGZpbGw9Im5vbmUiIHN0cm9rZT0iI2EyOWM5MCIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIiBzdHJva2Utd2lkdGg9IjIiIHRyYW5zZm9ybT0ibWF0cml4KDAgMSAtMSAwIDQzIC0zMykiLz48L3N2Zz4=') no-repeat center center;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
    }

    &:hover,
    &:focus {
        background: var(--color-gray-lighter);
    }

    @media (--screen-large) {
        width: 7rem;
        height: 7rem;

        .page-header.-home & {
            display: block;
        }
    }
}

.page-header__date {
    display: block;
    position: relative;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 2.4rem;
    color: var(--color-white);
    margin: 2rem 0 0;

    &::before {
        display: inline-block;
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI3IiB2aWV3Qm94PSIwIDAgMjYgMjciIHdpZHRoPSIyNiIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2JiOTBjMSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyLjUiIHRyYW5zZm9ybT0idHJhbnNsYXRlKDIpIj48cmVjdCBoZWlnaHQ9IjIyLjUiIHJ4PSIyLjUiIHdpZHRoPSIyMi41IiB5PSIyLjUiLz48cGF0aCBkPSJtMTYuMjUgMHY1Ii8+PHBhdGggZD0ibTYuMjUgMHY1Ii8+PHBhdGggZD0ibTAgMTBoMjIuNSIvPjwvZz48L3N2Zz4=');
        background-size: cover;
        width: 2.3rem;
        height: 2.5rem;
        margin-right: 1.5rem;
        vertical-align: middle;
        position: relative;
        top: -.2rem;
    }
}

.page-header__guests {
    display: block;
    position: relative;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 2.4rem;
    color: var(--color-white);
    margin: 2rem 0 0;

    &::before {
        display: inline-block;
        content: '';
        background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjI0IiB2aWV3Qm94PSIwIDAgMzAgMjQiIHdpZHRoPSIzMCIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2JiOTBjMSIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgxIDEpIj48cGF0aCBkPSJtMjAgMjIuNXYtMi41YzAtMi43NjE0MjM3LTIuMjM4NTc2My01LTUtNWgtMTBjLTIuNzYxNDIzNzUgMC01IDIuMjM4NTc2My01IDV2Mi41Ii8+PGNpcmNsZSBjeD0iMTAiIGN5PSI1IiByPSI1Ii8+PHBhdGggZD0ibTI3LjUgMjIuNXYtMi41Yy0uMDAxNjk5Ni0yLjI3ODYwMjgtMS41NDM3NTE1LTQuMjY3ODQ5OC0zLjc1LTQuODM3NSIvPjxwYXRoIGQ9Im0xOC43NS4xNjI1YzIuMjEyMzkzNS41NjY0NjIyNCAzLjc1OTgwNDEgMi41NTk5ODg5MyAzLjc1OTgwNDEgNC44NDM3NXMtMS41NDc0MTA2IDQuMjc3Mjg3NzctMy43NTk4MDQxIDQuODQzNzUiLz48L2c+PC9zdmc+');
        background-size: cover;
        width: 2.8rem;
        height: 2.3rem;
        margin-right: 1.5rem;
        vertical-align: middle;
        position: relative;
        top: -.2rem;
    }
}

.page-header__breadcrumb {
    display: block;
    margin: 0 0 2rem;
}
