.navigation-sub {
    margin: 4rem 0 0;
    display: none;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .-menu-active & {
        @media (--screen-mini-large) {
            display: block;
        }

        @media (--screen-large-xlarge) {
            margin: 0;
        }
    }
    @media (--screen-xlarge) {
        display: block;
    }
}

.navigation-sub__item {
    display: block;

    a {
        transition: all .2s ease;
        font-size: 1.5rem;
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        line-height: 2;
        text-decoration: none;
        color: var(--color-gray);

        &:hover,
        &:focus {
            color: var(--color-purple-light);
        }

        @media (--screen-large) {
            .-menu-active & {
                @media (--screen-large-xlarge) {
                    font-size: 1.8rem;
                }
            }
        }
    }
}

.navigation-sub__item__total {
    display: inline-block;
    border-radius: 50%;
    background: var(--color-purple-light);
    width: 1.8rem;
    height: 1.8rem;
    vertical-align: middle;
    font-size: 1.2rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-normal);
    color: var(--color-white);
    text-align: center;
    line-height: 1.4;
    position: relative;
    margin: -.2rem 0 0 .4rem;
}
