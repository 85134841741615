.contact {
    padding: 0 2rem 7rem;

    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 1.6;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    @media (--screen-large) {
        padding: 0 7rem 7rem;
        display: flex;
        flex-direction: row;

        > div {
            flex-basis: 50%;
            width: 50%;
        }
    }
}

.contact__form {
    order: -1;
}

.contact__info__title {
    display: block;
    color: var(--color-purple-light);

    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 2.4rem;

    line-height: 1.25;
}

.contact__info {
    p {
        margin: auto auto 7rem;
        max-width: 62rem;
        @media (--screen-large) {
            max-width: 38rem;
        }
    }

    a {
        color: var(--color-purple-light);
    }
}