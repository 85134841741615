.twitter-home-widget {
    max-width: 62rem;
    margin: 0 auto;
}

.twitter-home-widget__title {
    display: block;
    color: var(--color-purple-light);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-semi-bold);
    font-size: 2rem;
    line-height: 1.15;
    text-decoration: none;
    position: relative;
    padding: .4rem 0;
    transition: all .2s ease;
    margin: 0 0 2rem;

    small {
        display: block;
        padding-top: .5rem;
        font-weight: var(--font-weight-normal);
        font-size: 1.3rem;
        text-transform: uppercase;
        color: var(--color-gold);
    }

    &::before {
        display: block;
        content: '';
        background: url('avatar.png') no-repeat 0 0;
        background-size: cover;
        width: 5rem;
        height: 5rem;
        float: left;
        margin: -.4rem 1.5rem 2rem 0;
        transition: all .2s ease;
    }

    &:hover,
    &:focus {
        color: var(--color-purple);

        small {
            color: var(--color-gray-light);
        }

        &::before {
            opacity: .9;
        }
    }
}

.twitter-home-widget__list {
    display: block;
    list-style: none;
    margin: 0;
    padding: 0;
}

.tweet {
    background: var(--color-beige);
    padding: 2rem;
    margin: 0 0 1.5rem;
    color: var(--color-blue-dark);
}

.tweet__gallery__item {
    display: block;
    position: relative;
    margin: 0 0 1.5rem;
    width: 100%;
    font-size: 0;

    @media (--screen-medium-large) {
        margin-right: 2rem;
        width: 13rem;
        height: 13rem;

        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            flex: 0 0 auto;
        }
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: color-mod(var(--color-black) a(20%));
        opacity: 0;
        transition: all .2s ease;
    }

    &:hover,
    &:focus {
        &::after {
            opacity: 1;
        }
    }
}

.tweet__gallery__item__image {
    width: 100%;

    @media (--screen-medium-large) {
        width: 13rem;
        height: 13rem;
        object-fit: cover;
    }
}

.tweet__container {
    @media (--screen-medium-large) {
        display: flex;
        flex-direction: row;
        justify-items: flex-start;
    }
}

.tweet__content {
    font-size: 1.6rem;
}

.tweet__footer {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.tweet__footer__time {
    margin-left: auto;
    .tweet__footer__item {
        margin-right: 0;
        svg {
            margin: 0 0 0 1rem;
        }
    }
}

.tweet__footer__item {
    transition: all .2s ease;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-normal);
    color: var(--color-gold);
    display: inline-block;
    font-size: 1.3rem;
    line-height: 1.5;
    text-decoration: none;
    margin-right: 2rem;

    @media (--screen-medium-large) {
        margin-right: 3rem;
    }

    svg {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        fill: var(--color-gold);
        transition: all .2s ease;
        margin-right: .5rem;
        vertical-align: middle;
    }

    &:hover,
    &:focus {
        color: var(--color-purple-light);

        svg {
            fill: var(--color-purple-light);
        }
    }
}

.quoted-tweet {
    border: .1rem solid var(--color-gold);
    margin: .5rem 0 2rem;
    padding: 2rem;

    @media (--screen-medium-large) {
        display: flex;
        flex-direction: row;
        justify-items: center;
    }

    .tweet__gallery__item {
        @media (--screen-medium-large) {
            margin-bottom: 0;
        }
    }
}

.quoted-tweet__text {
    margin-bottom: 0;
    font-size: 1.5rem;
    line-height: 1.4;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
}

.quoted-tweet__retweet {
    color: var(--color-gold);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;

    svg {
        display: inline-block;
        width: 2rem;
        height: 2rem;
        fill: var(--color-gold);
        transition: all .2s ease;
        margin-right: .5rem;
        vertical-align: middle;
    }
}

.quoted-tweet__name {
    display: block;
    color: var(--color-purple-light);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.6rem;
    line-height: 1.15;
    text-decoration: none;
    position: relative;
    transition: all .2s ease;
    margin: 0 0 1rem;

    a {
        font-weight: var(--font-weight-normal);
        font-size: 1.4rem;
        color: var(--color-gold);
        text-decoration: none;
    }
}