@charset "UTF-8";
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x           : 2rem !default;
$hamburger-padding-y           : 2rem !default;
$hamburger-layer-width         : 2rem !default;
$hamburger-layer-height        : .2rem !default;
$hamburger-layer-spacing       : .4rem !default;
$hamburger-layer-color         : var(--color-purple-light) !default;
$hamburger-layer-border-radius : .1rem !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

// To use CSS filters as the hover effect instead of opacity,
// set $hamburger-hover-use-filter as true and
// change the value of $hamburger-hover-filter accordingly.
$hamburger-hover-use-filter   : false !default;
$hamburger-hover-filter       : opacity(100%) !default;
$hamburger-active-hover-filter: opacity(100%) !default;

// Hamburger
// ==================================================
.hamburger {
    padding: $hamburger-padding-y $hamburger-padding-x;
    display: inline-block;
    cursor: pointer;

    transition-property: opacity, filter;
    transition-duration: .15s;
    transition-timing-function: linear;

    // Normalize (<button>)
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible;

    &:hover {
        @if $hamburger-hover-use-filter == true {
            filter: $hamburger-hover-filter;
        } @else {
            opacity: $hamburger-hover-opacity;
        }
    }

    &.-active {
        &:hover {
            @if $hamburger-hover-use-filter == true {
                filter: $hamburger-active-hover-filter;
            } @else {
                opacity: $hamburger-active-hover-opacity;
            }
        }

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: $hamburger-active-layer-color;
        }
    }
}

.hamburger-box {
    width: $hamburger-layer-width;
    height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: $hamburger-layer-height / -2;

    &,
    &::before,
    &::after {
        width: $hamburger-layer-width;
        height: $hamburger-layer-height;
        background-color: $hamburger-layer-color;
        border-radius: $hamburger-layer-border-radius;
        position: absolute;
        transition-property: transform;
        transition-duration: .15s;
        transition-timing-function: ease;
    }

    &::before,
    &::after {
        content: '';
        display: block;
    }

    &::before {
        top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }

    &::after {
        bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
    }
}

//slider
.hamburger {
    .hamburger-inner {
        top: $hamburger-layer-height / 2;

        &::before {
            top: $hamburger-layer-height + $hamburger-layer-spacing;
            transition-property: transform, opacity;
            transition-timing-function: ease;
            transition-duration: .15s;
        }

        &::after {
            top: ($hamburger-layer-height * 2) + ($hamburger-layer-spacing * 2);
        }
    }

    &.-active {
        .hamburger-inner {
            $y-offset: $hamburger-layer-spacing + $hamburger-layer-height;

            transform: translate3d(0, $y-offset, 0) rotate(45deg);

            &::before {
                transform: rotate(-45deg) translate3d($hamburger-layer-width / -7, $hamburger-layer-spacing * -1, 0);
                opacity: 0;
            }

            &::after {
                transform: translate3d(0, $y-offset * -2, 0) rotate(-90deg);
            }
        }
    }
}

.hamburger {
    position: fixed;
    top: .7rem;
    right: 0;
    z-index: 2;
    outline: none;

    &.-fixed {
        z-index: 999;
        background-color: var(--color-purple);

        .hamburger-inner,
        .hamburger-inner::before,
        .hamburger-inner::after {
            background-color: var(--color-white);
        }

        &.-active {
            background-color: transparent;

            .hamburger-inner,
            .hamburger-inner::before,
            .hamburger-inner::after {
                background-color: $hamburger-active-layer-color;
            }
        }
    }

    @media (--screen-xlarge) {
        display: none;
    }
}
