/* page-footer */
.page-footer {
    flex-shrink: 0;
    color: var(--color-purple-lighter);
    background: var(--color-purple-light);
    font-family: var(--font-sofia);
    font-size: 1.2rem;
    font-weight: var(--font-weight-light);

    a {
        color: var(--color-white);
        font-family: var(--font-sofia);
    }
}

.page-footer__top {
    padding: 7rem 2rem 3.5rem;

    @media (--screen-medium-large) {
        padding: 11rem 7rem 5rem;
    }
}

.page-footer__middle {
    margin-top: 4rem;
    line-height: 1.7;
    font-size: 1.6rem;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    p {
        margin-top: 0;
    }

    > div, > nav {
        margin-bottom: 6rem;
        box-sizing: border-box;
    }

    .page-footer__address {
        padding-right: 3rem;
    }

    @media (--screen-large) {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        flex-wrap: wrap;

        .page-footer__address, .page-footer__social {
            flex-basis: 100%;
        }

        > div, > nav {
            flex-basis: 50%;

            @media (--screen-xlarge) {
                margin-bottom: 0;
                flex-basis: 30%;

                &:last-child {
                    flex-basis: 12.4rem;
                    margin-left: auto;
                }
            }
        }

        @media (--screen-xlarge) {
            flex-wrap: nowrap;

            .page-footer__address, .page-footer__social {
                flex-basis: 30%;
            }
        }
    }
}

.page-footer__bottom {
    border-top: .1rem solid color-mod(var(--color-gray) a(30%));
    padding: 3.5rem 2rem;
    text-transform: uppercase;
    text-align: center;
    white-space: pre-line;

    @media (--screen-medium-large) {
        padding: 3.5rem 7rem;
    }

    @media (--screen-large) {
        white-space: normal;
        text-align: left;
    }

    a {
        transition: all .2s ease;
        text-transform: uppercase;
        text-decoration: none;
        line-height: 1.5;
        display: inline-block;
        margin-top: 1rem;

        @media (--screen-large) {
            margin-left: 2rem;
        }

        &.-credits {
            clear: both;

            @media (--screen-large) {
                float: right;
            }
        }

        &:hover,
        &:focus {
            color: var(--color-purple-lighter);
        }
    }
}
