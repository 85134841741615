.media {
    display: block;
    clear: both;
    max-width: 70rem;
    margin: 7rem 0;

    @media (--screen-xlarge) {
        padding-left: 12rem;

        .news__detail & {
            padding-left: 0;
        }
    }
}

.media__download {
    display: block;
    clear: both;
    margin: 3rem 0 0;
    text-align: center;
}
