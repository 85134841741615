.form__entry {
    position: relative;

    &.-half {
        @media (--screen-medium-large) {
            display: block;
            clear: none;
            float: left;
            width: 100%;
            max-width: calc(50% - 1rem);
            margin-right: 2rem;

            &:nth-child(2n) {
                margin-right: 0;
            }
        }
    }

    &.-invalid {
        // see form__field.scss
        // see form__message.scss
    }

    &.-password-strength {
        // see password-strength.scss
    }
}
