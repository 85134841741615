.sidebar {
    background: var(--color-beige);
    padding: 1rem 2.5rem;
    box-sizing: border-box;
    position: relative;

    .-menu-active & {
        @media (--screen-mini-large) {
            position: fixed;
            z-index: 100;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
        }
    }

    @media (--screen-xlarge) {
        flex: 1 0 30rem;
        max-width: 30rem;
        overflow: hidden;
        padding: 2.5rem;
        border-right: .1rem solid var(--color-gray-lighter);
        width: 30rem;
        min-height: 80rem;
        position: fixed;
        top: 0;
        left: 0;
        bottom: 0;
        right: auto;
        @media screen and (max-height: 800px) {
            height: 100vh;
            min-height: 100vh;
            overflow-y: scroll;
        }
    }
}

.sidebar__logo {
    height: 5rem;
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-normal);
    font-size: 1.6rem;
    line-height: 1;
    letter-spacing: .1rem;
    text-transform: uppercase;
    text-decoration: none;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
    transition: all .2s ease;

    img {
        height: 100%;
    }

    span {
        border-left: .1rem solid var(--color-gray-lighter);
        margin-left: 2rem;
        padding-left: 2rem;
        color: var(--color-purple-light);

        @media (--screen-xlarge) {
            font-size: 1.9rem;
            line-height: 1.25;
        }
    }

    @media (--screen-xlarge) {
        height: 9rem;
    }

    &:hover,
    &:focus {
        span {
            color: var(--color-purple);
        }
    }
}

.sidebar__menu {
    .-menu-active & {
        @media (--screen-mini-large) {
            background: var(--color-beige);
            position: absolute;
            z-index: 1;
            top: 11rem;
            right: 0;
            bottom: 0;
            left: 0;
            display: flex;
            flex-direction: column;
            padding: 3rem;
            overflow-y: scroll;
        }

        @media (--screen-large-xlarge) {
            flex-direction: row;
            align-items: center;
            padding: 10rem;

            * {
                flex: 0 1 50%;
            }
        }
    }
}

.sidebar__menu__sub {
    @media (--screen-large-xlarge) {
        .-menu-active & {
            margin-left: 20vw;
        }
    }

    @media (--screen-xlarge) {
        @media screen and (min-height: 800px) {
            position: absolute;
            bottom: 3rem;
        }
    }
}
