table:not([class]) {
    width: 100%;
    border: solid var(--color-gray-lighter);
    border-width: .1rem .1rem 0 .1rem;

    td {
        padding: .6rem .8rem;
        border: solid var(--color-gray-lighter);
        border-width: 0 0 .1rem .1rem;
        vertical-align: top;

        &:first-child {
            white-space: nowrap;
            border-left: 0;
        }
    }
}

table:not([class])[border='0'] {
    border: 0;

    td {
        border: 0;
        &:first-child {
            padding-left: 0;
        }
    }
}
