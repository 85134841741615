.page-footer__partners {
    margin-top: 6rem;
}

.page-footer__partners__title {
    font-weight: var(--font-weight-light);
    text-transform: uppercase;
    display: block;
    margin-bottom: 1rem;
    text-align: center;

    @media (--screen-large) {
        text-align: left;
    }
}

.page-footer__partners__list {
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: -4rem;
    justify-content: center;

    a {
        display: inline-block;
        margin: 0 4rem 4rem 0;

        &:last-child {
            margin-right: 0;
        }
    }

    @media (--screen-large) {
        justify-content: flex-start;
    }

    .page-footer__partners.-quality-labels & {
        align-items: flex-start;
        margin-top: 1.5rem;
    }
}
