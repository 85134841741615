figure {
    margin: 0 0 4rem;
    position: relative;

    &.large {
        max-width: 98rem;

        @media (--screen-large) {
            width: calc(100% - 37rem);
        }

        img {
            width: 100%;
        }
    }

    &.left:not(.large) {
        float: left;
        margin-right: 3rem;
        clear: left;
    }

    &.right:not(.large) {
        float: right;
        margin-left: 3rem;
    }

    &.center:not(.large) {
        img {
            display: block;
            margin: 0 auto;
        }
    }

    figcaption {
        color: var(--color-gray-light);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-style: italic;
        font-size: 1.6rem;
        line-height: 1.5;
        padding: 3rem;
        position: relative;

        @media (--screen-large) {
            padding: 3rem 3rem 3rem 6rem;
            position: absolute;
            top: 0;
            left: 100%;
            width: 37rem;
            box-sizing: border-box;
        }

        &::after {
            display: block;
            content: '';
            width: .2rem;
            height: 4.6rem;
            background: var(--color-gold);
            position: absolute;

            top: -2.3rem;
            left: 4.2rem;

            @media (--screen-large) {
                top: 4.2rem;
                left: -4.5rem;
                width: 9rem;
                height: .2rem;
            }
        }
    }
}
