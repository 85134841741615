/* Body */
body {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100%;
    min-width: 32rem;
    margin: 0;

    background-color: var(--color-white);
    word-wrap: break-word; // Don't let long words break the layout on small area's

    &.-menu-active {
        overflow: hidden;

        /*
            Prevent iOS safari from still showing main content
        */
        @supports (-webkit-overflow-scrolling: touch) {
            main {
                opacity: 0;
            }
        }
    }
}

.body {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    min-height: 100%;
    margin: 0 auto;

    @media (--screen-xlarge) {
        flex-direction: row;
        min-height: 100vh;
    }
}

html.-menu-active {
    overflow: hidden;
}
