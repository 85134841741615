@import "../../lib/vendor/CookieConsent/cookie-consent.scss";

:root {
    --cookie-consent-max-width: 60em;
    --cookie-consent-background-color: var(--color-beige);
    --cookie-consent-options-background-color: var(--color-white);
}

.cookie-consent {
    .form-label {
        font-weight: bold;
        margin-left: 3px;
    }

    .form-row:not(:last-child) {
        margin-bottom: 1rem;
    }
}

.cookie-consent__inner {
    margin-block-end: 0;
}

.cookie-consent__title {
    font-size: 3rem;
    margin-block-end: 2rem;
}

.cookie-consent__option-description {
    font-size: 1.6rem;
}
