.form__label, .form__legend {
    display: block;
    cursor: pointer;

    color: var(--color-black);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 0 0 .5rem;

    transition: all .2s ease;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    small {
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-normal);
        font-size: 1.3rem;
        color: var(--color-gray-light);
        padding-left: .7rem;

    }
}
