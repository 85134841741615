.alert-message {
    display: block;
    color: var(--color-black);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-bold);
    font-size: 1.6rem;
    line-height: 1.5;
    padding: 0 0 .5rem;

    transition: all .2s ease;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }
}