/* stylelint-disable declaration-colon-space-after, max-empty-lines */
:root {
    --color-white                   : #FFFFFF;
    --color-black                   : #000000;

    --color-red                     : #E3182F;

    --color-blue-dark               : #171717;

    --color-purple                  : #621C6D;
    --color-purple-light            : #772583;
    --color-purple-lighter          : #CFA4D6;

    --color-gray                    : #7E757F;
    --color-gray-light              : #A2A2A2;
    --color-gray-lighter            : #DADADA;

    --color-beige                   : #F5F4F3;
    --color-gold                    : #A29C90;

    --font-kepler                   : 'kepler-std', serif;
    --font-sofia                    : 'sofia-pro', sans-serif;

    --font-weight-thin              : 100;
    --font-weight-extra-light       : 200;
    --font-weight-light             : 300;
    --font-weight-normal            : 400;
    --font-weight-medium            : 500;
    --font-weight-semi-bold         : 600;
    --font-weight-bold              : 700;
    --font-weight-extra-bold        : 800;
    --font-weight-black             : 900;
}

@custom-media --screen-max          (width >= 1920px);
@custom-media --screen-xlarge       (width >= 1366px);

@custom-media --screen-large-xlarge (width >= 1024px) and (width < 1366px);
@custom-media --screen-large        (width >= 1024px);

@custom-media --screen-medium-large (width >= 768px);
@custom-media --screen-medium       (width >= 768px) and (width < 1024px);

@custom-media --screen-small-large  (width >= 420px);
@custom-media --screen-small-medium (width >= 420px) and (width < 1024px);
@custom-media --screen-small        (width >= 420px) and (width < 768px);

@custom-media --screen-mini-large   (width < 1366px);
@custom-media --screen-mini-medium  (width < 1024px);
@custom-media --screen-mini-small   (width < 768px);
@custom-media --screen-mini         (width < 420px);

@custom-selector :--focus :hover, :focus, :active;
