:root {
    --cookie-consent-max-width: 42em;
    --cookie-consent-color: inherit;
    --cookie-consent-background-color: white;
    --cookie-consent-options-color: inherit;
    --cookie-consent-options-background-color: lightgray;
    --cookie-consent-iframe-template-background-color: lightgray;
    --cookie-consent-iframe-template-max-width: 100%;
}

.cookie-consent {
    position: fixed;
    z-index: var(--cookie-consent-z-index, 999);
    inset-block-end: var(--cookie-consent-bottom, 0);
    transform: var(--cookie-consent-transform, none);

    overflow-y: auto;

    box-sizing: border-box;
    inline-size: 100%;
    max-inline-size: var(--cookie-consent-max-width);
    max-block-size: 100%;
    margin-block: var(--cookie-consent-margin-block, auto 0);
    margin-inline: var(--cookie-consent-margin-inline, auto 0);
    padding: var(--cookie-consent-padding, 2em);

    color: var(--cookie-consent-color);

    background-color: var(--cookie-consent-background-color);
    border: 0;

    a:not([class]) {
        color: var(--cookie-consent-link-color, inherit);
    }

    &.-is-modal {
        --cookie-consent-margin-inline: auto;

        @media (min-width: 45rem), (min-height: 31rem) {
            --cookie-consent-bottom: 50%;
            --cookie-consent-transform: translate3d(0, calc(50% + .5px), 0); // Fix blurry rendering in Chrome
        }
    }

    &:not(.-is-modal) {
        @media (max-width: 45rem), (max-height: 31rem) {
            --cookie-consent-max-width: none;
        }
    }
}

.cookie-consent__title {
    margin-block-start: 0;
    color: inherit;
}

.cookie-consent__options {
    margin: 0 0 2em;
    padding: 1em;
    color: var(--cookie-consent-options-color);
    background: var(--cookie-consent-options-background-color);
}

.cookie-consent__option-description {
    display: block;
    margin-block: 0;
    font-size: 90%;
}

.cookie-consent__buttons:not([hidden]) {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;

    .-general {
        margin-block-start: 1em;
    }
}

template.-pending-consent {
    cursor: pointer;

    position: relative;

    display: block;

    aspect-ratio: var(--cookie-consent-cover-aspect-ratio, 16 / 9);
    max-inline-size: var(--cookie-consent-iframe-template-max-width);
    min-block-size: 300px;
    margin-inline: auto;
    padding-block-end: var(--cookie-consent-iframe-template-bottom-whitespace, 4rem);

    &::before {
        content: attr(title);

        display: flex;
        align-items: center;
        justify-content: center;

        box-sizing: border-box;
        inline-size: 100%;
        block-size: 100%;
        padding: 2rem 4rem;

        color: var(--cookie-consent-iframe-template-color, inherit);
        text-align: center;

        background-color: var(--cookie-consent-iframe-template-background-color);
        background-image: var(--cookie-consent-cover-image);
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }
}
