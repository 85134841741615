.page-header__video {
    width: 100%;
    box-sizing: border-box;
    margin: 0;
    padding: 0 0 56.25%;
    position: relative;

    iframe {
        max-width: unset;
    }

    &.-playing .page-header__video__box {
        background-image: none !important;
    }
}

.page-header__video__box {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    background-size: cover;
    background-position: center center;
}

.page-header__video__player {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    pointer-events: none;
}

div.page-header__video__player {
    width: 100%;
    height: 100%;

    iframe {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
}
