.event-cases__detail {
    max-width: 98rem;
    padding-bottom: 5rem;
    margin: 0 auto 5rem;

    @media (--screen-xlarge) {
        margin: 0 37rem 5rem auto;
        min-width: 70rem;
    }
}

.event-cases__detail__logo {
    float: right;
    margin: 0 0 3rem 3rem;

    @media (--screen-xlarge) {
        margin-right: -20rem;
    }
}
