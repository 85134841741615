.media__files__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.media__files__item {
    padding: 2rem 0;
    border-top: .1rem solid var(--color-gray-lighter);
    overflow: hidden;
    display: flex;
    align-items: flex-start;

    &:first-child {
        border: 0;
    }

    p {
        margin: 0;
        color: var(--color-gray-lighter);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 1.3rem;
        text-transform: uppercase;
        letter-spacing: .1rem;
    }

    strong {
        letter-spacing: 0;
        display: block;
        margin-bottom: 1rem;
        color: var(--color-purple-light);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 1.6rem;
        text-transform: none;

        @media (--screen-medium-large) {
            font-size: 1.8rem;
        }
    }

    a {
        margin-left: auto;
        display: inline-block;
        position: relative;
        color: var(--color-purple-light);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-normal);
        font-size: 1.5rem;
        text-decoration: none;
        border: .2rem solid var(--color-gray-lighter);
        padding: 1.5rem;
        transition: all .2s ease;

        &::before {
            display: inline-block;
            content: '';
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMTUgMTgiIHdpZHRoPSIxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2RhZGFkYSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEpIj48cGF0aCBkPSJtNy41IDB2MTEiLz48cGF0aCBkPSJtMTMgMTUuNWgtMTIiLz48cGF0aCBkPSJtMiA2IDUuNSA1IDUuNS01Ii8+PC9nPjwvc3ZnPg==');
            width: 1.4rem;
            height: 1.6rem;
            margin-right: 1rem;
            vertical-align: middle;
            position: relative;
            top: -.2rem;
        }

        &:hover,
        &:focus {
            background: color-mod(var(--color-gray-lighter) a(30%));
        }
    }
}
