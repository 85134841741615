blockquote:not(.twitter-tweet) {
    display: block;
    overflow: hidden;
    position: relative;
    margin: 3rem 0;
    padding: 2rem 0 0 3rem;

    @media (--screen-small) {
        padding-left: 4rem;
    }

    @media (--screen-medium-large) {
        padding-left: 8rem;
        margin: 7rem 0;
    }

    p {
        position: relative;
        z-index: 1;
        font-family: var(--font-kepler);
        font-weight: var(--font-weight-medium);
        font-style: italic;
        font-size: 2rem;
        line-height: 1.4;
        color: var(--color-purple-light) !important;

        @media (--screen-small) {
            font-size: 2.4rem;
        }

        @media (--screen-medium-large) {
            font-size: 2.8rem;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }

    &::after {
        display: block;
        content: '';
        width: 13rem;
        height: 9rem;
        position: absolute;
        top: 0;
        left: 0;
        background-image: url(data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9Ijg5IiB2aWV3Qm94PSIwIDAgMTI2IDg5IiB3aWR0aD0iMTI2IiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciPjxwYXRoIGQ9Im01NTMuMTE5MjU3IDE2MzEgMi4zNzA5ODkgOS44NzkxMmMtMjAuOTQzNzQxIDMuOTUxNjUtMzkuOTExNjU4IDE4LjU3Mjc2LTQzLjg2MzMwNyAzNi4zNTUxOCAzLjU1NjQ4NC0xLjk3NTgzIDguMjk4NDYzLTMuNTU2NDkgMTMuNDM1NjA3LTMuNTU2NDkgOS4wODg3OTQgMCAxNS44MDY1OTggNy4xMTI5NyAxNS4wMTYyNjggMTguMTc3NTktLjc5MDMzIDE0LjYyMTEtMTIuMjUwMTEzIDI3LjI2NjM4LTI2LjQ3NjA1MSAyNy4yNjYzOC0xMy40MzU2MDcgMC0yMS4zMzg5MDYtMTEuODU0OTUtMjAuNTQ4NTc2LTI5LjI0MjIgMS45NzU4MjQtMzIuNDAzNTMgMzIuMDA4MzU5LTU1LjcxODI2IDYwLjA2NTA3LTU4Ljg3OTU4em02Mi44MzEyMjQgMCAyLjc2NjE1NSA5Ljg3OTEyYy0yMS4zMzg5MDYgMy45NTE2NS0zOS45MTE2NTggMTguNTcyNzYtNDMuODYzMzA4IDM2Ljc1MDM0IDMuNTU2NDg1LTIuMzcwOTkgOC4yOTg0NjQtMy45NTE2NSAxMy40MzU2MDgtMy45NTE2NSA5LjA4ODc5NCAwIDE1LjgwNjU5NyA3LjExMjk3IDE1LjAxNjI2OCAxOC4xNzc1OS0uNzkwMzMgMTQuNjIxMS0xMi42NDUyNzggMjcuMjY2MzgtMjYuODcxMjE2IDI3LjI2NjM4LTEzLjQzNTYwOCAwLTIxLjMzODkwNi0xMS44NTQ5NS0yMC4xNTM0MTItMjguODQ3MDQgMS45NzU4MjUtMzIuNzk4NjkgMzIuMDA4MzYtNTYuMTEzNDIgNTkuNjY5OTA1LTU5LjI3NDc0eiIgZmlsbD0iI2Y1ZjRmMyIgdHJhbnNmb3JtPSJ0cmFuc2xhdGUoLTQ5MyAtMTYzMSkiLz48L3N2Zz4=);
        background-repeat: no-repeat;
    }
}