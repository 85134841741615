/**
  This is the helper class.
  These are styles that can be applied to any component in general

  All helper classes should be prefixed with .h-
 */

.h-hide {
    display: none;
}
