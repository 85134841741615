.button {
    transition: all .2s ease;

    cursor: pointer;
    box-sizing: border-box;
    display: inline-block;
    margin: 0;
    padding: 2rem 2.3rem;
    border: 0;

    color: var(--color-white);
    background: var(--color-purple-light);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-semi-bold);
    font-size: 1.6rem;
    line-height: 1;
    text-decoration: none;
    outline: none;

    vertical-align: middle;

    @media (--screen-medium-large) {
        padding: 2.7rem 3.4rem;
        font-size: 1.8rem;
    }

    &:hover,
    &:focus {
        text-decoration: none;
        background: var(--color-purple);
        color: var(--color-white);
    }

    &.-beige {
        background: var(--color-beige);
        color: var(--color-purple-light);

        &:hover,
        &:focus {
            background: var(--color-white);
            color: var(--color-purple);
        }
    }

    &.-transparent {
        background: none;
        color: var(--color-white);

        &:hover,
        &:focus {
            color: var(--color-beige);
        }
    }

    &.-download {
        position: relative;
        color: var(--color-purple-light);
        background: none;
        font-size: 1.5rem;
        border: .2rem solid var(--color-gray-lighter);
        font-weight: var(--font-weight-normal);
        padding: 1.5rem;

        &::before {
            display: inline-block;
            content: '';
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjE4IiB2aWV3Qm94PSIwIDAgMTUgMTgiIHdpZHRoPSIxNSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2RhZGFkYSIgc3Ryb2tlLWxpbmVjYXA9InNxdWFyZSIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIDEpIj48cGF0aCBkPSJtNy41IDB2MTEiLz48cGF0aCBkPSJtMTMgMTUuNWgtMTIiLz48cGF0aCBkPSJtMiA2IDUuNSA1IDUuNS01Ii8+PC9nPjwvc3ZnPg==');
            width: 1.4rem;
            height: 1.6rem;
            margin-right: 1rem;
            vertical-align: middle;
            position: relative;
            top: -.2rem;
        }

        &:hover,
        &:focus {
            background: color-mod(var(--color-gray-lighter) a(30%));
        }
    }
}
