.room-highlights {
    display: block;
    clear: both;
    margin: 0 -2rem 7rem -2rem;

    @media (--screen-medium) {
        display: flex;
        flex-direction: row;
        .room-highlights__item {
            flex-basis: 50%;
            width: 50%;
            margin: 2rem;
        }
    }

    @media (--screen-large) {
        margin: 0 -7rem;
        padding-top: 7rem;
    }
}

.room-highlights__item {
    margin-bottom: 7rem;
    background: var(--color-beige);

    @media (--screen-large) {
        margin-bottom: 26rem;
        display: flex;
        flex-direction: row;

        > * {
            flex-basis: 50%;
            width: 50%;
        }

        &:nth-child(even) {
            .room-highlights__item__content {
                order: -1;
            }
        }
    }
}

.room-highlights__item__content {
    padding: 3rem 2rem;
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    line-height: 1.6;

    p {
        margin-bottom: 4rem;
    }

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    @media (--screen-large) {
        padding: 4rem 8rem;
    }

    @media (--screen-xlarge) {
        padding: 8rem 12rem;
    }
}

.room-highlights__item__image {
    position: relative;
    margin: 0;
    overflow: hidden;
    text-align: center;

    @media (--screen-large) {
        margin: -7rem 0;
    }

    img {
        width: 100%;

        @media (--screen-large) {
            position: absolute;
            top: 50%;
            left: 0;
            object-fit: cover;
            transform: translateY(-50%);
        }
    }
}

.room-highlights__item__title {
    font-size: 3rem;
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    display: block;
    line-height: 1.2;
    margin-bottom: 4rem;

    @media (--screen-medium-large) {
        font-size: 3.7rem;
    }
}
