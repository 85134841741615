.highlights__item {
    margin-bottom: 7rem;

    @media (--screen-large) {
        margin-bottom: 8rem;
        display: flex;
        flex-direction: row;
        align-items: start;

        > * {
            flex-basis: 50%;
            width: 50%;
        }

        &:nth-child(even) {
            .highlights__item__content {
                order: -1;
            }

            .highlights__item__image::before {
                right: auto;
                left: -4.5rem;
            }
        }
    }
}

.highlights__item__content {
    padding: 3rem 2rem 0;
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    line-height: 1.6;

    p {
        margin-bottom: 4rem;
    }

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    @media (--screen-large) {
        padding: 4rem 8rem 0;
    }

    @media (--screen-xlarge) {
        padding: 4rem 12rem 0;
    }
}

.highlights__item__image {
    position: relative;

    &::before {
        display: block;
        content: '';
        width: .2rem;
        height: 4.6rem;
        background: var(--color-gold);
        position: absolute;

        bottom: -2.3rem;
        left: 3.2rem;
        z-index: 2;

        @media (--screen-large) {
            top: 12.5rem;
            left: auto;
            bottom: auto;
            right: -4.5rem;
            width: 9rem;
            height: .2rem;
        }
    }

    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: color-mod(var(--color-black) a(20%));
        opacity: 0;
        transition: all .2s ease;
        z-index: 1;
    }

    img {
        width: 100%;
    }

    &:hover,
    &:focus {
        &::after {
            opacity: 1;
        }
    }
}

.highlights__item__number {
    color: var(--color-gold);
    font-size: 2.4rem;
    letter-spacing: .2rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    display: block;
    margin-bottom: 2rem;
}

.highlights__item__title {
    font-size: 3rem;
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    display: block;
    line-height: 1.2;
    margin-bottom: 4rem;
    text-decoration: none;
    color: var(--color-black);
    transition: all .2s ease;

    @media (--screen-medium-large) {
        font-size: 3.7rem;
    }

    &:hover,
    &:focus {
        color: var(--color-purple-light);
    }
}
