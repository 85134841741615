.page-footer__partof {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.page-footer__partof__seperator {
    color: var(--color-purple-lighter);
    font-family: var(--font-sofia);
    font-size: 1.2rem;
    font-weight: var(--font-weight-light);
    margin: 0 2rem;
    display: inline-block;
    text-transform: uppercase;
    text-align: center;
    position: relative;

    em {
        background: var(--color-purple-light);
        font-style: normal;
        position: relative;
        z-index: 1;
        padding: 1rem;
    }

    &::after {
        position: absolute;
        top: 50%;
        left: 50%;
        display: block;
        content: '';
        width: 10rem;
        height: .1rem;
        background: var(--color-purple-lighter);
        transform: rotate(-55deg);
        margin: 0 0 0 -5rem;
    }
}
