.language-selection {
    margin: 5rem 0 0;
    display: none;

    .-menu-active & {
        @media (--screen-mini-large) {
            display: block;
        }
    }
    @media (--screen-xlarge) {
        display: block;
    }

    .page-header__content & {
        display: block;
        position: absolute;
        margin-top: 0;

        @media (--screen-large) {
            bottom: -5rem;
        }

        @media (--screen-mini-medium) {
            right: 0;
            top: 2rem;

            a, .-active a {
                color: var(--color-white);
            }
        }
    }
}

.language-selection__title {
    font-size: 1.3rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    line-height: 1.4;
    text-transform: uppercase;
    color: var(--color-gray-light);
    display: block;
    margin-bottom: 1rem;
}

.language-selection__list {
    margin: 0;
    padding: 0;
    list-style: none;
}

.language-selection__list__item {
    border-left: .1rem solid var(--color-gray-lighter);
    padding: 0 1.5rem;
    display: inline-block;

    &:first-child {
        border-left: 0;
        padding-left: 0;
    }

    a {
        font-size: 1.6rem;
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        line-height: 1;
        text-decoration: none;
        color: var(--color-gray);
        transition: all .2s ease;

        @media (--screen-medium-large) {
            font-size: 1.8rem;
        }

        &:hover,
        &:focus {
            color: var(--color-purple-light);
        }

        small, span {
            font-size: 1.6rem;

            @media (--screen-medium-large) {
                font-size: 1.8rem;
            }
        }

        small {
            @media (--screen-medium-large) {
                display: none;
            }

            @media (--screen-xlarge) {
                display: inline-block;
            }
        }

        span {
            display: none;

            @media (--screen-medium-large) {
                display: inline-block;
            }

            @media (--screen-xlarge) {
                display: none;
            }
        }
    }

    &.-active a {
        color: var(--color-purple-light);
        border-bottom: .1rem solid var(--color-gold);
    }
}
