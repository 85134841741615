.main__container {
    position: relative;

    box-sizing: border-box;
    display: flex;
    flex-direction: column;

    width: 100%;
    max-width: 192rem;
    margin: 0;

    background-color: var(--color-white);
    word-wrap: break-word; // Don't let long words break the layout on small area's

    @media (--screen-xlarge) {
        min-height: 100vh;
        margin-left: 30rem;
        width: calc(100% - 30rem);
        max-width: 162rem;

        .-hide-sidebar-and-footer & {
            margin-left: 0;
            width: 100%;
        }
    };
}

main {
    flex: 1 0 auto; // used for sticky footer using flex-box

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        flex: 0 0 auto;
    }
}
