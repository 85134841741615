.news__detail {
    max-width: 98rem;
    padding-bottom: 5rem;
    margin: 0 auto 5rem;

    @media (--screen-xlarge) {
        min-width: 70rem;
        margin: 0 37rem 5rem auto;
        padding-left: 12rem;
    }
}
