a:not([class]) {
    color: var(--color-purple-light);
    transition: all .2s ease;

    &:hover,
    &:focus {
        color: var(--color-purple);
        text-decoration: none;
    }
}

a.link {
    text-decoration: none;
    position: relative;
    transition: all .2s ease;
    line-height: 1;

    &.-line {
        &::after {
            display: inline-block;
            content: '';
            width: 5rem;
            height: .1rem;
            background: var(--color-white);
            margin: 0 0 0 1.5rem;
            position: relative;
            top: -.5rem;
        }

        &:hover,
        &:focus {
            color: var(--color-purple-lighter);
        }
    }

    &.-arrow {
        display: inline-block;
        color: var(--color-purple-light);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-medium);
        font-size: 1.6rem;
        padding: 0 1rem 0 5rem;
        position: relative;

        &::before {
            display: block;
            position: absolute;
            left: 0;
            top: .1rem;
            content: '';
            width: 3rem;
            height: 1.2rem;
            background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMzcgMTIiIHdpZHRoPSIzNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3MjU4MyIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yMykiPjxwYXRoIGQ9Im0zMyAyOWgtMzIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJtMzAgMjQgNSA1LTUgNSIvPjwvZz48L3N2Zz4=') no-repeat 100% 50%;
            margin-right: 2rem;
            transition: all .2s ease;
        }

        &:hover, &:focus {
            color: var(--color-purple);
            padding: 0 0 0 6rem;

            &::before {
                width: 4rem;
                background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMzcgMTIiIHdpZHRoPSIzNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2QzYzdiMSIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yMykiPjxwYXRoIGQ9Im0zMyAyOWgtMzIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJtMzAgMjQgNSA1LTUgNSIvPjwvZz48L3N2Zz4=');
            }
        }
    }

    &.-breadcrumb {
        color: var(--color-purple-lighter);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 1.6rem;

        &::before {
            display: inline-block;
            content: '';
            width: .4rem;
            height: .8rem;
            background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEwIiB2aWV3Qm94PSIwIDAgNiAxMCIgd2lkdGg9IjYiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0ibTM3MSAxNjMgNCA0LTQgNCIgZmlsbD0ibm9uZSIgc3Ryb2tlPSIjZmZmIiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiIHN0cm9rZS13aWR0aD0iMiIgdHJhbnNmb3JtPSJtYXRyaXgoLTEgMCAwIC0xIDM3NiAxNzIpIi8+PC9zdmc+');
            margin-right: 1.5rem;
            transition: all .2s ease;
            vertical-align: middle;
            position: relative;
            top: -.2rem;
        }

        &:hover,
        &:focus {
            color: var(--color-white);
        }
    }
}
