article,
.article {
    text-align: left;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 1.6;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    h1, h2, h3, h4, p, ul, ol, table {
        max-width: 70rem;
        color: var(--color-blue-dark);
    }

    .youtube-embed-wrapper {
        max-width: 70rem;
        margin-left: 0;
    }

    h1 {
        font-family: var(--font-kepler);
        font-weight: var(--font-weight-medium);
        font-size: 3rem;
        line-height: 1.25;
        margin: 0 0 4rem;

        @media (--screen-medium-large) {
            font-size: 3.7rem;
        }
    }

    h2 {
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 2.4rem;
        line-height: 1.25;
        margin: 0 0 4rem;

        @media (--screen-medium-large) {
            font-size: 3rem;
        }
    }

    h3 {
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 2rem;
        line-height: 1.25;
        margin: 0 0 3rem;
        color: var(--color-purple);

        @media (--screen-medium-large) {
            font-size: 2.4rem;
        }
    }

    h4 {
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-normal);
        font-size: 1.5rem;
        line-height: 1.25;
        margin: 0 0 2rem;
        text-transform: uppercase;
        color: var(--color-blue-dark);

        @media (--screen-medium) {
            font-size: 1.6rem;
        }

        @media (--screen-large) {
            font-size: 1.8rem;
        }
    }

    p {
        margin: 0 0 3rem;
    }

    &.-padded {
        @media (--screen-xlarge) {
            padding-left: 12rem;
        }
    }

    p + h1, figure + h1, div + h1,
    p + h2, figure + h2, div + h2,
    p + h3, figure + h3, div + h3,
    p + h4, figure + h4, div + h4,
    p + h5, figure + h5, div + h5,
    p + h6, figure + h6, div + h6 {
        margin-top: 6rem;
    }

}
