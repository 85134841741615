.rooms-form {
    clear: both;
    margin-bottom: 7rem;

    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 1.6;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    @media (--screen-large) {
        display: flex;
        flex-direction: row;
        margin-bottom: 17rem;
        > * {
            flex-basis: 50%;
            width: 50%;
        }

        img {
            max-width: 100%;
            flex-basis: 25%;
        }
    }

    .form {
        margin-left: auto;
    }
}
