ul:not([class]),
ol:not([class]),
.list {
    display: block;
    margin: 0 0 2rem 1rem;
    padding: 0;
    list-style: none;

    font-size: 1.6rem;
    line-height: 1.8;
    color: var(--color-black);

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    li {
        position: relative;
        padding: 0;

        &::before {
            content: '\2022';
            color: var(--color-gold);
            font-weight: var(--font-weight-bold);
            display: inline-block;
            margin: 0 1rem 0 -2rem;
            width: 1rem;
        }
    }
}

ol:not([class]) {
    margin-left: 2rem;
    li {
        counter-increment: li;
        &::before {
            content: counter(li) '.';
            font-size: 1.5rem;
            text-align: right;
            width: 3rem;
            margin-left: -3rem;
        }
    }
}