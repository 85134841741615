.youtube-embed-wrapper {
    width: 100%;
    position: relative;
    display: block;
    margin: 0 auto 3rem;

    .youtube-embed-inner {
        display: block;
        padding: 56.25% 0 0 !important;
    }

    iframe, template {
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
    .youtube-embed-wrapper:after {
        width: 100%;
        height: 100%;
    }
}
