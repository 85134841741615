.social {
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    font-size: 1.6rem;
    line-height: 1.6;

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    @media (--screen-large) {
        display: flex;
        flex-direction: row;
        margin-bottom: 11rem;

        > .social__column {
            flex-basis: 50%;
            width: 50%;
        }
    }
}

.social__column {
    box-sizing: border-box;
    padding: 2rem;

    @media (--screen-medium-large) {
        padding: 7rem;
    }
}

.social__title {
    display: block;
    text-align: left;
    margin: 0 0 1.5rem;
    color: var(--color-blue-dark);
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    font-size: 3rem;

    @media (--screen-medium) {
        font-size: 3.7rem;
    }

    @media (--screen-large) {
        font-size: 4.6rem;
    }
}

.social__listing {
    margin: 6rem 0 0;
    justify-content: flex-start;
    border-top: .1rem solid var(--color-gray-lighter);
    text-align: center;

    @media (--screen-small-large) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        text-align: left;
    }

    dd, dt {
        padding: 2rem 0 0;
        box-sizing: border-box;
        display: block;

        @media (--screen-small-large) {
            width: 50%;
            padding: 2rem 0;
            border-bottom: .1rem solid var(--color-gray-lighter);
            flex: 1 1 auto;
        }

        a {
            text-decoration: none;
            color: inherit;
            transition: all .2s ease;
            &:hover,
            &:focus {
                color: var(--color-purple-light);
                svg {
                    fill: var(--color-gold);
                }
            }
        }
    }

    dt {
        color: var(--color-gold);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-normal);
        font-size: 1.3rem;
        text-transform: uppercase;

        &.-center {
            display: flex;
            flex-direction: column;
            justify-content: center;
        }
    }

    dd {
        color: var(--color-purple-light);
        font-family: var(--font-sofia);
        font-weight: var(--font-weight-light);
        font-size: 1.5rem;
        margin: 0 0 0 auto;
        box-sizing: border-box;
        padding: 1rem 0 2rem;

        border-bottom: .1rem solid var(--color-gray-lighter);

        @media (--screen-small-large) {
            text-align: right;
            padding: 2rem 1rem 2rem 0;
        }

        svg {
            vertical-align: middle;
            width: 4rem;
            text-align: center;
            fill: var(--color-purple-light);
            transition: all .2s ease;
        }
    }
}