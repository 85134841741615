.page-content__children {
    margin: 0 0 7rem;

    @media (--screen-medium-large) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin: 0 -3rem 7rem -3rem;
        align-items: start;
        &.-even {
            padding-bottom: 35rem;
        }
    }

    @media (--screen-large) {
        margin: 0 -6rem 7rem -6rem;
    }
}

.page-content__children__item {
    display: block;
    margin: 2rem 0;
    text-decoration: none;
    color: var(--color-blue-dark);
    transition: all .2s ease;

    @media (--screen-medium-large) {
        flex-basis: calc(50% - 4rem);
        width: calc(50% - 4rem);
        margin: 2rem;

        &:first-child {
            top: 0;
        }
        &:nth-child(2n) {
            position: relative;
            top: 35rem;
        }
    }

    @media (--screen-large) {
        flex-basis: calc(50% - 12rem);
        width: calc(50% - 12rem);
        margin: 6rem;
    }

    &:hover,
    &:focus {
        background: var(--color-white);

        .page-content__children__item__image::after {
            opacity: 1;
        }

        .page-content__children__item__link {
            color: var(--color-purple);
            padding-left: 6rem;

            &::before {
                width: 4rem;
                background-image: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMzcgMTIiIHdpZHRoPSIzNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iI2QzYzdiMSIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yMykiPjxwYXRoIGQ9Im0zMyAyOWgtMzIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJtMzAgMjQgNSA1LTUgNSIvPjwvZz48L3N2Zz4=');
            }
        }

        .page-content__children__item__content {
            border: .1rem solid var(--color-gray-lighter);
            border-top: 0;
        }
    }
}

.page-content__children__item__image {
    position: relative;
    img {
        width: 100%;
    }
    &::after {
        display: block;
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background: rgba(0, 0, 0, .2);
        opacity: 0;
        transition: all .2s ease;
    }
}

.page-content__children__item__content {
    padding: 3rem 2rem;
    box-sizing: border-box;
    font-size: 1.6rem;
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-light);
    line-height: 1.6;
    border: .1rem solid var(--color-beige);
    border-top: 0;
    background: var(--color-beige);

    @media (--screen-medium-large) {
        font-size: 1.8rem;
    }

    p {
        margin-bottom: 4rem;
    }

    @media (--screen-large) {
        padding: 4rem;
    }
}

.page-content__children__item__title {
    font-size: 3rem;
    font-family: var(--font-kepler);
    font-weight: var(--font-weight-medium);
    display: block;
    line-height: 1.2;
    margin-bottom: 2rem;

    @media (--screen-medium-large) {
        font-size: 3.7rem;
    }
}

.page-content__children__item__link {
    display: inline-block;
    color: var(--color-purple-light);
    font-family: var(--font-sofia);
    font-weight: var(--font-weight-medium);
    font-size: 1.6rem;
    padding-left: 5rem;
    position: relative;
    line-height: 1;
    transition: all .2s ease;

    &::before {
        display: block;
        position: absolute;
        left: 0;
        top: .1rem;
        content: '';
        width: 3rem;
        height: 1.2rem;
        background: url('data:image/svg+xml;base64,PHN2ZyBoZWlnaHQ9IjEyIiB2aWV3Qm94PSIwIDAgMzcgMTIiIHdpZHRoPSIzNyIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj48ZyBmaWxsPSJub25lIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIHN0cm9rZT0iIzc3MjU4MyIgc3Ryb2tlLXdpZHRoPSIyIiB0cmFuc2Zvcm09InRyYW5zbGF0ZSgwIC0yMykiPjxwYXRoIGQ9Im0zMyAyOWgtMzIiIHN0cm9rZS1saW5lY2FwPSJzcXVhcmUiLz48cGF0aCBkPSJtMzAgMjQgNSA1LTUgNSIvPjwvZz48L3N2Zz4=') no-repeat 100% 50%;
        margin-right: 2rem;
        transition: all .2s ease;
    }
}