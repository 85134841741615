.menu-detail__container {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: space-between;
}

.menu-detail__content {
    flex: 1 1 50rem;
}

.menu-detail__course {
    margin-block: 4rem .8rem;
}

.menu-detail__dish {
    margin-block: 0 3.2rem;

    h4 {
        text-transform: none;
        font-size: 1.6rem;
        margin-block: 0 .8rem;

        @media (--screen-medium-large) {
            font-size: 1.8rem;
        }
    }

    p {
        margin: 0;
        font-size: 90%
    }
}

.menu-detail__allergens {
    list-style: none;
    display: flex;
    padding-left: 0;
    flex-wrap: wrap;
    gap: .3rem;

    img {
        max-width: 4rem;
    }
}

.menu-detail__partners {
    font-size: 2rem;
    max-width: 48.2rem;
    font-family: var(--font-sofia);
    flex: 0 1 48.2rem;
}

.menu-detail__partner-images {
    display: flex;
    gap: 1.6rem;
    list-style: none;
    padding-left: 0;
    flex-wrap: wrap;
    margin-block: 2rem;

    li {
        background-color: white;
        box-shadow: 0 0 7px 0 rgba(0, 0, 0, .16);
        border-radius: 4.5rem;
        display: inline-flex;
        justify-content: center;
        align-items: center;
        height: 9.2rem;
        aspect-ratio: 1;

        &.-wide {
            padding-inline: 3.3rem;
            aspect-ratio: auto;

            img {
                max-width: 100%;
            }
        }
    }
}

.menu-detail__foot-note {
    margin-block: 0;
    font-size: 1.6rem;
    font-family: var(--font-sofia);
    line-height: 1.5;
    color: var(--color-blue-dark);
    font-weight: 300;
}
