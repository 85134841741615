/* html */
html {
    @include font-smoothing(); // Disable font-smoothing on places where you don't want it
    font-size: 10px; // base for rem units
    line-height: 1;

    box-sizing: border-box;
    width: 100%;
    min-width: 320px;
    margin: 0 auto;

    background: var(--color-white); // set to overwrite Firefox themes
    overflow-x: hidden;

    touch-action: manipulation; // remove 350ms delay on ios
}
